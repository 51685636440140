@import "variables.scss";
@import "~bootstrap";
@import "./assets/fonts/Source_Sans_Pro";

body {
  background-color: $bg-color !important;
  font-size: $font-size;
}

.table {
  max-width: 100% !important;
  margin-bottom: 0;
}

.table-50 {
  max-width: 50% !important;
}

.table thead th {
  color: inherit !important;
  font-weight: bold !important;
  border-bottom: none;
}

.table th {
  padding: 3px 6px 3px 6px;
}

.table td {
  padding: 6px;
}

.btn-icon {
  background-color: #fff;
  min-width: fit-content !important;
}

.navbar {
  z-index: 1000;
}

h1 {
  font-size: 1.35rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
h2 {
  font-size: 1.25rem !important;
}
.btn {
  font-size: $font-size;
  min-width: 150px;
}
.btn-primary {
  color: #fff;
  background-color: $btn-background-color !important;
  border-color: $border-btn-color !important;
}
.btn-primary:not([disabled]):hover,
.btn-primary:not([disabled]):focus,
.btn-primary:not([disabled]):active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: $base-color !important;
  border-color: $base-color !important; /*set the color you want here*/
}
.btn-outline-secondary:hover {
  background-color: #fff !important;
}
label.btn-outline-secondary {
  background-color: #efefef !important;
  border: 1px solid #ccc !important;
  color: #888 !important;
}
label.btn-outline-secondary.active {
  background-color: #fff !important;
  color: #888 !important;
  font-weight: bold !important;
}

.gray {
  color: $body-color !important;
  border-color: $body-color !important;
}
.gray:hover {
  border-color: $base-color !important;
}

.control-label.required:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 5px;
  top: 7px;
}

.small-icon {
  font-size: $font-size;
  text-align: center;
  padding: 4px;
}

.primary-icon,
.link {
  color: $primary;
}

.link {
  cursor: pointer;

  &.text-underline {
    border-bottom: 1px dotted;

    &:hover {
      border-bottom: none;
    }
  }
}

.verified-icon {
  color: #4caf50;
}

.header-icon {
  color: $header-icon-color;
}

.sidebar-icon {
  color: $sidebar-icon-color;
}
.white-container {
  border-top: 1px solid $primary;
  background-color: white;
  padding: 14px;
}

.btn-container {
  border-top: 1px solid #dee2e6;
}

.btn-container-filter {
  margin-bottom: 6px !important;
}

.form-control:focus {
  box-shadow: none !important;
}
.form-control {
  font-size: $font-size;
}

.form-group {
  margin-bottom: 0.45rem !important;
}

label {
  margin-bottom: 0.1rem !important;
}

.table-link-no-border {
  border: none;
  outline: none;
}

.custom-control > * {
  cursor: pointer;
}

.form-control {
  transition: padding 0.3s;
}

input,
textarea,
select {
  &.form-control {
    border: 1px solid #ced4da;
  }
}

dp-day-calendar.dp-material .dp-selected {
  background-color: $primary !important;
}

dp-day-calendar.dp-material .dp-current-day,
dp-month-calendar.dp-material .dp-current-month {
  border-color: $primary !important;
}

dp-date-picker {
  width: 100%;
}

dp-date-picker.dp-material div {
  .dp-input-container {
    /*width: 221px;*/

    &::after {
      content: "";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=");
      background-origin: content-box;
      background-size: contain;
      height: 1.2em;
      margin-inline-start: 24px;
      opacity: 1;
      padding-bottom: 2px;
      padding-inline-start: 3px;
      padding-inline-end: 3px;
      padding-top: 2px;
      width: 1.2em;
      background-repeat: no-repeat;
      outline: none;
      position: absolute;
      right: 5px;
      top: calc(50% + 1px);
      font-size: 22px;
      transform: translateY(-50%);
    }
  }

  .dp-picker-input {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    font-size: 16px;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    @include form-control-focus($ignore-warning: true);

    &::placeholder {
      color: #495057;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}

.filter-ddl {
  min-width: 250px;
}

@media screen and (min-width: 1200px) {
  .filterForm {
    width: 100% !important;
  }
}

.filterForm label {
  font-weight: bold;
  display: block;
}

.filter-form-div {
  padding-right: 0px !important;
}

.is-invalid {
  .dp-input-container::after {
    right: 35px;
  }

  input {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

@media screen and (min-width: 1200px) {
  .platformForm {
    width: 50% !important;
  }
}

.platformForm label {
  margin-top: 10px;
}

/*
 * See: https://stackoverflow.com/questions/57813871/why-are-my-br-tags-showing-in-my-browser-display-for-angular-project
 */
p.break-newline {
  white-space: pre-wrap;
}
.minSizes {
  min-width: 20%;
  max-width: 20%;
}

.fc-button-primary {
  background-color: #efefef !important;
  border: 1px solid #ccc !important;
  color: #888 !important;
  font-size: 12px !important;
}

.fc-today-button.fc-button-primary,
.fc-button-active {
  background-color: #fff !important;
  color: #888 !important;
  font-weight: bold !important;
}

.fc-col-header-cell-cushion {
  font-weight: bold;
  color: #666 !important;
  font-size: 14px !important;
}

.fc .fc-button .fc-icon {
  font-size: $font-size-small;
}

.btn-sm-xs {
  font-size: 0.8rem !important;
}

accordion-group.panel .card {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
}

accordion-group.panel .card .card-header {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
}

accordion-group.panel .card .card-body {
  margin: 0 !important;
  padding: 0.5rem !important;
  padding-top: 0 !important;
  padding-left: 0.8rem !important;
}

.pagination,
.page-item {
  font-size: 0.845rem !important;
}

.no-element-table {
  font-size: 0.95rem !important;
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}

.breadcrumb-eth {
  background: none !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 0.845rem !important;
  padding: 6px 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bi-info-circle-fill {
  color: $primary;
}

.text-error {
  color: #ff3001;
}

.modal-header {
  font-weight: bold;
  font-size: 16px;
}

.tooltip-inner {
  background-color: #ff8742;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ff8742;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ff8742;
}

#viewerContainer {
  cursor: default !important;
}

.brandImage {
  width: 32%;
  padding: 30px;
  position: absolute;
  left: -10%;
  top: 58%;
  color: #ff7701;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  transform: rotate(-90deg);
  font-size: 50px;
  text-shadow: 2px 2px 1px rgba(111, 111, 111, 1);
}

.environment {
  width: 400px;
  padding: 30px;
  position: absolute;
  left: 70%;
  top: 4%;
  color: #ff7701;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  font-size: 55px;
  text-shadow: 2px 2px 1px rgba(111, 111, 111, 1);
}

.footerLogin {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
}

.footerLogin2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  background-color: transparent;
  text-align: center;
}

.btnLogin {
  margin-top: 5%;
}

.cardLogDetails {
  margin-bottom: 1rem;
}

.w-auto-custom {
  display: inline-block;
  width: max-content;
  max-width: 200%;
  min-width: 80%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.full-width-table {
  width: 100%;
  table-layout: fixed;
}

.col-w-5 {
  width: 15%;
}

.ql-snow .ql-tooltip {
  z-index: 1000;
}

.ql-snow p {
  margin-top: revert;
}

.is-invalidQuill .ql-editor {
    border-color: #dc3545 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) bottom 0.75em;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-style: solid;
    border-width: 2px;
  }

  .ql-editor{
    min-height:150px;
}
