$base-color: #ff7701;
$bg-color: #f5f5f5;
$body-color: #666;
$primary: $base-color;
$secondary: lighten($base-color, 10%);
$link-color: #ff7701;
$btn-background-color: #f49239;
$border-btn-color: #f38521;
$header-icon-color: #fff;
$header-icon-color-hover: #000;
$sidebar-icon-color: #999;
$sidebar-icon-color-hover: $base-color;
$logo-bg-color: #ff5f01;
$font-family-sans-serif: 'Source Sans Pro';
$font-size: 15px !important;
$font-size-small: 13px !important;
